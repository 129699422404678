import React, {createContext, useState} from "react";

export const AuthContext = createContext();

export const AuthProvider = ({children}) => {

    let dataUser = localStorage.getItem('_user')
    if (dataUser) {
        dataUser = JSON.parse(dataUser)
    } else {
        dataUser = null
    }
     const [user, setUser] = useState(dataUser)

    return (
        <AuthContext.Provider value={{
            user,
            setUser
        }}>
            {children}
        </AuthContext.Provider>
    )
}
