import React, {useContext, useLayoutEffect, useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '../../components/bootstrap/Button';
import {HeaderRight} from '../../layout/Header/Header';
import OffCanvas, {
    OffCanvasBody,
    OffCanvasHeader,
    OffCanvasTitle,
} from '../../components/bootstrap/OffCanvas';
import Alert from '../../components/bootstrap/Alert';
import Dropdown, {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from '../../components/bootstrap/Dropdown';
import Icon from '../../components/icon/Icon';
import ThemeContext from '../../contexts/themeContext';
// import showNotification from '../../../components/extras/showNotification';
import useDarkMode from '../../hooks/useDarkMode';
import Popovers from '../../components/bootstrap/Popovers';
import Avatar from "../../components/Avatar";

import iconAvatar from '../../assets/avatars/avatar.png'
import MenuPerfil from "./MenuPerfil";
import {AuthContext} from "../../contexts/authContext";

const CommonHeaderRight = ({beforeChildren, afterChildren}) => {
    const {darkModeStatus, setDarkModeStatus} = useDarkMode();
    const {fullScreenStatus, setFullScreenStatus} = useContext(ThemeContext);
    const {user, setUser} = useContext(AuthContext);

    const [offcanvasStatus, setOffcanvasStatus] = useState(false);
    const [offcanvasStatusPerfil, setOffcanvasStatusPerfil] = useState(false);

    const styledBtn = {
        color: darkModeStatus ? 'dark' : 'light',
        hoverShadow: 'default',
        isLight: !darkModeStatus,
        size: 'lg',
    };


    const closeOffCanvasPerfil = () => {
        setOffcanvasStatusPerfil(false)
    }

    return (
        <HeaderRight>
            <div className='row g-3'>
                {beforeChildren}
                {/* Dark Mode */}
                {/*<div className='col-auto'>*/}
                {/*    <Popovers trigger='hover' desc='Modo Oscuro / Claro'>*/}
                {/*        <Button*/}
                {/*            // eslint-disable-next-line react/jsx-props-no-spreading*/}
                {/*            {...styledBtn}*/}
                {/*            icon={darkModeStatus ? 'DarkMode' : 'LightMode'}*/}
                {/*            onClick={() => setDarkModeStatus(!darkModeStatus)}*/}
                {/*            aria-label='Toggle fullscreen'*/}
                {/*            data-tour='dark-mode'*/}
                {/*        />*/}
                {/*    </Popovers>*/}
                {/*</div>*/}

                {/*/!*	Full Screen *!/*/}
                {/*<div className='col-auto'>*/}
                {/*    <Popovers trigger='hover' desc='Pantalla completa'>*/}
                {/*        <Button*/}
                {/*            // eslint-disable-next-line react/jsx-props-no-spreading*/}
                {/*            {...styledBtn}*/}
                {/*            icon={fullScreenStatus ? 'FullscreenExit' : 'Fullscreen'}*/}
                {/*            onClick={() => setFullScreenStatus(!fullScreenStatus)}*/}
                {/*            aria-label='Toggle dark mode'*/}
                {/*        />*/}
                {/*    </Popovers>*/}
                {/*</div>*/}

                {/* Lang Selector */}
                {/*<div className='col-auto'>*/}
                {/*    <Dropdown>*/}
                {/*        <DropdownToggle hasIcon={false}>*/}
                {/*            <Button*/}
                {/*                // eslint-disable-next-line react/jsx-props-no-spreading*/}
                {/*                {...styledBtn}*/}
                {/*                icon={getLangWithKey(i18n.language)?.icon}*/}
                {/*                aria-label='Change language'*/}
                {/*                data-tour='lang-selector'*/}
                {/*            />*/}
                {/*        </DropdownToggle>*/}
                {/*        <DropdownMenu isAlignmentEnd data-tour='lang-selector-menu'>*/}
                {/*            {Object.keys(LANG).map((i) => (*/}
                {/*                <DropdownItem key={LANG[i].lng}>*/}
                {/*                    <Button*/}
                {/*                        icon={LANG[i].icon}*/}
                {/*                        onClick={() => changeLanguage(LANG[i].lng)}>*/}
                {/*                        {LANG[i].text}*/}
                {/*                    </Button>*/}
                {/*                </DropdownItem>*/}
                {/*            ))}*/}
                {/*        </DropdownMenu>*/}
                {/*    </Dropdown>*/}
                {/*</div>*/}

                {/* Quick Panel */}
                {/*<div className='col-auto'>*/}
                {/*    <Dropdown>*/}
                {/*        <DropdownToggle hasIcon={false}>*/}
                {/*            /!* eslint-disable-next-line react/jsx-props-no-spreading *!/*/}
                {/*            <Button {...styledBtn} icon='Tune' aria-label='Quick menu'/>*/}
                {/*        </DropdownToggle>*/}
                {/*        <DropdownMenu isAlignmentEnd size='lg' className='py-0 overflow-hidden'>*/}
                {/*            <div className='row g-0'>*/}
                {/*                <div*/}
                {/*                    className={classNames(*/}
                {/*                        'col-12',*/}
                {/*                        'p-4',*/}
                {/*                        'd-flex justify-content-center',*/}
                {/*                        'fw-bold fs-5',*/}
                {/*                        'text-info',*/}
                {/*                        'border-bottom border-info',*/}
                {/*                        {*/}
                {/*                            'bg-l25-info': !darkModeStatus,*/}
                {/*                            'bg-lo25-info': darkModeStatus,*/}
                {/*                        },*/}
                {/*                    )}>*/}
                {/*                    Quick Panel*/}
                {/*                </div>*/}
                {/*                <div*/}
                {/*                    className={classNames(*/}
                {/*                        'col-6 p-4 transition-base cursor-pointer bg-light-hover',*/}
                {/*                        'border-end border-bottom',*/}
                {/*                        {'border-dark': darkModeStatus},*/}
                {/*                    )}>*/}
                {/*                    <div className='d-flex flex-column align-items-center justify-content-center'>*/}
                {/*                        <Icon icon='Public' size='3x' color='info'/>*/}
                {/*                        <span>Dealers</span>*/}
                {/*                        <small className='text-muted'>Options</small>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*                <div*/}
                {/*                    className={classNames(*/}
                {/*                        'col-6 p-4 transition-base cursor-pointer bg-light-hover',*/}
                {/*                        'border-bottom',*/}
                {/*                        {'border-dark': darkModeStatus},*/}
                {/*                    )}>*/}
                {/*                    <div className='d-flex flex-column align-items-center justify-content-center'>*/}
                {/*                        <Icon icon='Upcoming' size='3x' color='success'/>*/}
                {/*                        <span>Inbox</span>*/}
                {/*                        <small className='text-muted'>Configuration</small>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*                <div*/}
                {/*                    className={classNames(*/}
                {/*                        'col-6 p-4 transition-base cursor-pointer bg-light-hover',*/}
                {/*                        'border-end',*/}
                {/*                        {'border-dark': darkModeStatus},*/}
                {/*                    )}>*/}
                {/*                    <div className='d-flex flex-column align-items-center justify-content-center'>*/}
                {/*                        <Icon icon='Print' size='3x' color='danger'/>*/}
                {/*                        <span>Print</span>*/}
                {/*                        <small className='text-muted'>Settings</small>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*                <div className='col-6 p-4 transition-base cursor-pointer bg-light-hover'>*/}
                {/*                    <div className='d-flex flex-column align-items-center justify-content-center'>*/}
                {/*                        <Icon icon='ElectricalServices' size='3x' color='warning'/>*/}
                {/*                        <span>Power</span>*/}
                {/*                        <small className='text-muted'>Mode</small>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </DropdownMenu>*/}
                {/*    </Dropdown>*/}
                {/*</div>*/}

                {/*	Notifications */}
                {/*<div className='col-auto'>*/}
                {/*    <Button*/}
                {/*        // eslint-disable-next-line react/jsx-props-no-spreading*/}
                {/*        {...styledBtn}*/}
                {/*        icon='Notifications'*/}
                {/*        onClick={() => setOffcanvasStatus(true)}*/}
                {/*        aria-label='Notifications'*/}
                {/*    />*/}
                {/*</div>*/}


                <div className='col-auto'>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                         onClick={() => setOffcanvasStatusPerfil(true)}
                    >
                        <div style={{lineHeight: '18px'}} className={'mr-10'}>
                            <p className={'m0 negrita neutral-100'}>{user?.username || ''}</p>
                            <p className={'m0'}>{user?.role?.alias || ''}</p>
                        </div>

                        <Avatar
                            src={iconAvatar}
                            srcSet={iconAvatar}
                            color={''}
                            size={48}
                        />
                    </div>

                </div>


                {afterChildren}
            </div>

            <OffCanvas
                id='notificationCanvas'
                titleId='offcanvasExampleLabel'
                placement='end'
                isOpen={offcanvasStatus}
                setOpen={setOffcanvasStatus}>
                <OffCanvasHeader setOpen={setOffcanvasStatus}>
                    <OffCanvasTitle id='offcanvasExampleLabel'>Notifications</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <Alert icon='ViewInAr' isLight color='info' className='flex-nowrap'>
                        Notificación 1
                    </Alert>
                    <Alert icon='ThumbUp' isLight color='warning' className='flex-nowrap'>
                        Notificación 2
                    </Alert>
                    <Alert icon='Inventory2' isLight color='danger' className='flex-nowrap'>
                        Notificación 3
                    </Alert>
                    <Alert icon='BakeryDining' isLight color='success' className='flex-nowrap'>
                        Notificación 4
                    </Alert>
                    <Alert icon='Escalator' isLight color='primary' className='flex-nowrap'>
                        Notificación 5
                    </Alert>
                </OffCanvasBody>
            </OffCanvas>


            <OffCanvas
                id='perfilCanvas'
                titleId='offcanvasPerfil'
                placement='end'
                isOpen={offcanvasStatusPerfil}
                setOpen={setOffcanvasStatusPerfil}>
                <OffCanvasHeader setOpen={setOffcanvasStatusPerfil}>
                    <span></span>
                    {/*<OffCanvasTitle id='offcanvasPerfil'>Perfil</OffCanvasTitle>*/}
                </OffCanvasHeader>
                <OffCanvasBody>
                    <MenuPerfil closeOffCanvasPerfil={()=>closeOffCanvasPerfil()}/>
                </OffCanvasBody>
            </OffCanvas>
        </HeaderRight>
    );
};
CommonHeaderRight.propTypes = {
    beforeChildren: PropTypes.node,
    afterChildren: PropTypes.node,
};
CommonHeaderRight.defaultProps = {
    beforeChildren: null,
    afterChildren: null,
};

export default CommonHeaderRight;
