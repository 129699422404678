import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import Icon from '../../components/icon/Icon';
import logotipo from '../../assets/logos/logo-fidem-blaco.png';
import logotipoCcder from '../../assets/logos/logo-ccder-blanco.png';
import logotipoCver from '../../assets/logos/logo-cver-blanco.png';

const Brand = ({asideStatus, setAsideStatus}) => {
  return (
    <div className="brand">
      <div className="brand-logo text-center" style={{width: '100%'}}>
        <h1 className="brand-title w-100">
          <a href="/" aria-label="Logo" alt="">
            <img style={{width: '140px'}} src={logotipo} alt="logo" />
          </a>
          <div style={{display:"flex"}}>
            <a href="/" aria-label="Logo" alt="">
              <img style={{width: '60px'}} src={logotipoCcder} alt="logo" />
            </a>
            <a href="/" aria-label="Logo" alt="" style={{marginLeft:12}}>
              <img style={{width: '60px'}} src={logotipoCver} alt="logo" />
            </a>
          </div>
        </h1>
      </div>
      <button
        type="button"
        className="btn brand-aside-toggle"
        aria-label="Toggle Aside"
        onClick={() => setAsideStatus(!asideStatus)}>
        <Icon icon="FirstPage" className="brand-aside-toggle-close" />
        <Icon icon="LastPage" className="brand-aside-toggle-open" />
      </button>
    </div>
  );
};
Brand.propTypes = {
  asideStatus: PropTypes.bool.isRequired,
  setAsideStatus: PropTypes.func.isRequired,
};

export default Brand;
