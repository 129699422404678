import React, {useCallback, useContext, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import Card, {CardBody} from '../../components/bootstrap/Card';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import Button from '../../components/bootstrap/Button';
import logotipo from '../../assets/logos/logo-fidem.png';
import logotipoCcder from '../../assets/logos/logo-ccder.png';
import logotipoCver from '../../assets/logos/logo-cver.png';
import useDarkMode from '../../hooks/useDarkMode';
import Icon from '../../components/icon/Icon';
import {_loader, _notify, _removeLoader} from '../../helpers/helpers';
import {AuthContext} from '../../contexts/authContext';
import {BASE_URL} from '../../env';
import api from '../../utils/api';

const LoginHeader = () => {
  return (
    <>
      <div className="text-center h1 fw-bold mt-5 mb-5">Inicio de Sesión</div>
      {/*<div className="text-center h4 text-muted mb-5">*/}
      {/*  Inicia sesión para continuar!*/}
      {/*</div>*/}
    </>
  );
};

const Login = () => {
  const navigate = useNavigate();
  const {darkModeStatus} = useDarkMode();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const {user, setUser} = useContext(AuthContext);

  const olvideContrasenia = (e) => {};

  const onChangeUsername = (e) => {
    setUsername(e.target.value);
  };
  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const iniciarSesionX = (e) => {
    _loader();
    setTimeout(() => {
      const dataUser = {
        usuario: {
          nombre: 'Diego Martínez',
          rol: 'Super Admin',
        },
        access_token: '',
        refresh_token: '',
      };
      localStorage.setItem('_user', JSON.stringify(dataUser));
      setUser(dataUser);
      _removeLoader();

      navigate('/');
    }, 2000);
  };

  const iniciarSesion = async () => {
    try {
      if (!username) {
        _notify('Introduce tu Usuario', 'danger');
        return;
      }
      if (!password) {
        _notify('Introduce tu Contraseña', 'danger');
        return;
      }
      _loader();
      const url = `${BASE_URL}/auth/login`;

      const payload = {
        username: username,
        password: password,
      };
      let response = await api.post(url, payload);
      response = response?.data?.data || null;
      localStorage.setItem('_user', JSON.stringify(response));
      setUser(response);
      _removeLoader();
      navigate('/');
    } catch (error) {
      // if (error?.response?.status === 401) {
      //   _notify(error?.response?.data?.message, 'danger');
      // } else {
      //   _notify('Ha ocurrio un error. Intente más tarde.', 'danger');
      // }
      _removeLoader();
    }
  };

  return (
    <PageWrapper
      isProtected={false}
      title={'Iniciar sesión'}
      className={classNames('bg-primary')}>
      <Page className="p-0">
        <div className="row h-100 align-items-center justify-content-center">
          <div className="col-xl-4 col-lg-6 col-md-8 shadow-3d-container">
            <Card className="shadow-3d-dark" data-tour="login-page">
              <CardBody>
                <div className="text-center">
                  <Link
                    to="/"
                    className={classNames(
                      'text-decoration-none  fw-bold display-2 logos-login',
                      {
                        'text-dark': !darkModeStatus,
                        'text-light': darkModeStatus,
                      },
                    )}>
                    <img className={'img-logo fidem'} src={logotipo} />
                    <img className={'img-logo ccder'} src={logotipoCcder} />
                    <img className={'img-logo cver'} src={logotipoCver} />
                  </Link>
                </div>

                <LoginHeader />

                <form className="row g-4">
                  <div className="col-12">
                    <FormGroup id="login-username" isFloating label="Usuario">
                      <Input
                        type="text"
                        // autoComplete='username'
                        onChange={(e) => onChangeUsername(e)}
                      />
                    </FormGroup>
                    <br />
                    <div className={'container-password'}>
                      <FormGroup
                        id="login-password"
                        isFloating
                        label="Contraseña">
                        <Input
                          type={showPassword ? 'text' : 'password'}
                          // autoComplete='password'
                          onChange={(e) => onChangePassword(e)}
                        />
                      </FormGroup>

                      {!showPassword && (
                        <div
                          onClick={() => setShowPassword(true)}
                          className={'button-eye'}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            className="size-6 icon-eye">
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                            />
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                            />
                          </svg>
                        </div>
                      )}

                      {showPassword && (
                        <div
                          onClick={() => setShowPassword(false)}
                          className={'button-eye'}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            className="size-6">
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
                            />
                          </svg>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-12">
                    <Button
                      color="primary"
                      className="w-100 py-3"
                      onClick={(e) => iniciarSesion(e)}>
                      Iniciar sesión
                    </Button>
                  </div>
                </form>
              </CardBody>
            </Card>
            {/*<div className='text-center'>*/}
            {/*    <a*/}
            {/*        href='/'*/}
            {/*        className={'text-decoration-none link-dark'}>*/}
            {/*        Políticas de privacidad*/}
            {/*    </a>*/}
            {/*</div>*/}
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};
Login.propTypes = {
  isSignUp: PropTypes.bool,
};
Login.defaultProps = {
  isSignUp: false,
};

export default Login;
