import Icon from "../../components/icon/Icon";
import React, {useContext} from "react";
import Avatar from "../../components/Avatar";
import iconAvatar from "../../assets/avatars/avatar.png";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../contexts/authContext";
import {_loader} from "../../helpers/helpers";


const MenuPerfil = ({closeOffCanvasPerfil}) => {
    const {user, setUser} = useContext(AuthContext);
    const navigate = useNavigate();

    const goToPerfil = () => {
        navigate('/perfil')
        closeOffCanvasPerfil()
    }

    const logout = () => {
        closeOffCanvasPerfil()
        _loader();
        localStorage.clear()
        setUser(null)
        location.reload()
    }

    return (
        <>
            <div className={'avatar-perfil'}>
                <Avatar
                    src={iconAvatar}
                    srcSet={iconAvatar}
                    color={''}
                    size={48}
                />
                <div style={{lineHeight:'18px'}} className={'ml-10'}>
                    <p className={'m0 negrita neutral-100'}>{user?.username || ''}</p>
                    <p className={'m0'}>{user?.role?.alias || ''}</p>
                </div>
            </div>
            <div className={'menu-perfil'}>
                <div
                    onClick={()=>goToPerfil()}
                    className={'item-menu'}>
                    <Icon icon='person' size={'2x'} color={'primary'}/>
                    <span>Perfil</span>
                </div>
                <div
                    onClick={()=>logout()}
                    className={'item-menu'}>
                    <Icon icon='logout' size={'2x'} color={'primary'}/>
                    <span>Cerrar sesión</span>
                </div>
            </div>
        </>

    )
}

export default MenuPerfil
