import React, {useLayoutEffect, forwardRef, useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import classNames from 'classnames';
import {AuthContext} from "../../contexts/authContext";

const PageWrapper = forwardRef(({isProtected = true, title, description, className, children}, ref) => {

    useLayoutEffect(() => {
        document.getElementsByTagName('TITLE')[0].text = `${title ? `${title} | ` : ''}${
            process.env.REACT_APP_SITE_NAME
        }`;
        document
            .querySelector('meta[name="description"]')
            .setAttribute('content', description || process.env.REACT_APP_META_DESC);
    });
    const navigate = useNavigate()
    const {user} = useContext(AuthContext)

    useEffect(() => {
        if (isProtected && !user) {
            // debugger
            navigate('/login');
        }
        if(!isProtected && user){
            navigate('/');
        }

        return () => {};

    }, []);

    return (
        <div ref={ref} className={classNames('page-wrapper', 'container-fluid', className)}>
            {children}
        </div>
    );
});
PageWrapper.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};
PageWrapper.defaultProps = {
    title: null,
    description: null,
    className: null,
};

export default PageWrapper;
