export const inicio = {
    inicio: {
        id: 'inicio',
        text: 'Inicio',
        path: '/',
        icon: 'home',
        iconImg: null,
        subMenu: null,
    },
}

// export const planteles = {
//     // pages: {
//     //     id: 'planteles',
//     //     text: 'Planteles',
//     //     icon: 'Extension',
//     // },
//     planteles: {
//         id: 'planteles',
//         text: 'Planteles',
//         path: '/planteles',
//         icon: 'school',
//         iconImg: null,
//         subMenu: null,
//     },
//
// }

export const usuarios = {
    // pages: {
    //     id: 'planteles',
    //     text: 'Planteles',
    //     icon: 'Extension',
    // },

    usuarios: {
        id: 'usuarios',
        text: 'Usuarios',
        path: '/usuarios',
        icon: 'groups',
        iconImg: null,
        subMenu: null,
    },

}
export const niveles = {
    // pages: {
    //     id: 'planteles',
    //     text: 'Planteles',
    //     icon: 'Extension',
    // },
    niveles: {
        id: 'niveles',
        text: 'Niveles',
        path: '/niveles',
        icon: 'stairs',
        iconImg: null,
        subMenu: null,
    },
}
export const tarjetas = {
    // pages: {
    //     id: 'planteles',
    //     text: 'Planteles',
    //     icon: 'Extension',
    // },
    tarjetas: {
        id: 'tarjetas',
        text: 'Tarjetas',
        path: '/tarjetas',
        icon: 'style',
        iconImg: null,
        subMenu: null,
    },
}

