import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router} from "react-router-dom";
// import './index.css';
import './styles/styles.scss'
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ThemeContextProvider} from './contexts/themeContext';
import {AuthProvider} from "./contexts/authContext";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AuthProvider>
        <Router>
            <React.StrictMode>
                <ThemeContextProvider>
                    <App/>
                </ThemeContextProvider>
            </React.StrictMode>
        </Router>
    </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
