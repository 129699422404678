import React from 'react';
import api from '../utils/api';
import {useContext} from 'react';
import {AuthContext} from '../contexts/authContext';
import {_notify} from '../helpers/helpers';

export const useAuthInterceptor = function () {
  // const {user, setUser, setResponseError} = useContext(AuthContext);
  const {user, setUser} = useContext(AuthContext);

  React.useEffect(() => {
    const authInterceptor = api.interceptors.response.use(
      async (response) => {
        // setResponseError(null);
        return response;
      },
      async (error) => {
        const response = error?.response || null;

        let url = response?.request?.responseURL || '';
        url = url.split('/').pop();

        if (response) {
          console.log('URL ERROR: ', response?.request?.responseURL);
          const status = response?.status;
          console.log(status);
          if (status === 500) {
            // _notify(
            //   'Ha ocurrido un error inesperado, intenta más tarde.',
            //   'danger',
            // );
            // setResponseError({
            //   status: status,
            //   error: '',
            //   message: 'Ha ocurrido un error inesperado, intenta más tarde.',
            //   icon: 'iconError',
            // });
          } else if (status === 401) {
            if (url !== 'login') {
              localStorage.clear();
              setUser(null);
              _notify('Sesión expirada. Vuelve a iniciar sesión.', 'danger');
              location.reload();
            } else {
                const message = response?.data?.message || ''
              _notify(message, 'danger');
            }
          } else {
            // const message =
            //   response?.data?.message ||
            //   'Algo ha salido mal, intenta más tarde.';
            // _notify(message, 'danger');
            // setResponseError({
            //   status: status,
            //   error: '',
            //   message: message,
            //   icon: 'iconError',
            // });
          }
        } else {
          console.log(error);
          _notify(
            'Algo ha salido mal. Revisa tu conexión a internet.',
            'danger',
          );
          // setResponseError({
          //   status: 0,
          //   error: 'Error de Conexión',
          //   message: 'Algo ha salido mal. Revisa tu conexión a internet.',
          //   icon: 'iconNetwork',
          // });
        }
        return Promise.reject(error);
      },
    );
    return () => {
      api.interceptors.response.eject(authInterceptor);
    };
  }, [user]);
};
