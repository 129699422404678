export const pages = {

    //Perfil
    perfil: {
        title: 'Inicio',
        path: '/perfil',
    },


    //Planteles
    agregar_plantel: {
        title: 'Agregar plantel',
        path: '/planteles/agregar-plantel',
    },
    editar_plantel: {
        title: 'Editar plantel',
        path: '/planteles/agregar-plantel',
    },
    detalle_plantel: {
        title: 'Detalle plantel',
        path: '/planteles/detalle-plantel',
    },

    //Niveles
    niveles: {
        title: 'Niveles',
        path: '/niveles/niveles',
    },
    // detalle_nivel: {
    //     title: 'Detalle plantel',
    //     path: '/niveles/detalle-nivel',
    // },



    //Tarjetas
    tarjetas: {
        title: 'Niveles',
        path: '/niveles/niveles',
    },

}

