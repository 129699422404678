import React, {useContext} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Content from '../Content/Content';
import Header, {HeaderLeft, HeaderRight} from "../Header/Header";
import WrapperOverlay from './WrapperOverlay';
import ThemeContext from '../../contexts/themeContext';
import ContentRight from "../Header/ContentRight";
import {AuthContext} from "../../contexts/authContext";

export const WrapperContainer = ({children, className, ...props}) => {
    const {rightPanel} = useContext(ThemeContext);
    return (
        <div
            className={classNames(
                'wrapper',
                {'wrapper-right-panel-active': rightPanel},
                className,
            )}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}>
            {children}
        </div>
    );
};
WrapperContainer.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};
WrapperContainer.defaultProps = {
    className: null,
};

const Wrapper = () => {

    const {user} = useContext(AuthContext)

    return (
        <>
            <WrapperContainer>
                {user &&
                <Header>
                    <HeaderLeft>
                    </HeaderLeft>
                    <HeaderRight>
                        <ContentRight/>
                    </HeaderRight>
                </Header>
                }
                <Content/>
            </WrapperContainer>
            <WrapperOverlay/>
        </>
    );
};

export default Wrapper;
