import React, {
    useContext, useRef, useState,
} from 'react';
import classNames from 'classnames';
import {motion} from 'framer-motion';
import Brand from '../Brand/Brand';
import Navigation, {NavigationLine} from '../Navigation/Navigation';
import {
    inicio,
    // planteles,
    usuarios,
    niveles,
    tarjetas
} from '../../routes/menu';
import ThemeContext from '../../contexts/themeContext';
import Tooltips from '../../components/bootstrap/Tooltips';
import useAsideTouch from '../../hooks/useAsideTouch';
import Icon from "../../components/icon/Icon";
import {Link} from 'react-router-dom';

const Aside = () => {
    const {asideStatus, setAsideStatus} = useContext(ThemeContext);
    let {asideStyle, touchStatus, hasTouchButton, asideWidthWithSpace, x} = useAsideTouch();
    const isModernDesign = process.env.REACT_APP_MODERN_DESGIN === 'true';
    const constraintsRef = useRef(null);
    return (
        <>
            <motion.aside
                style={asideStyle}
                className={classNames(
                    'aside',
                    {open: asideStatus},
                    {
                        'aside-touch-bar': hasTouchButton && isModernDesign,
                        'aside-touch-bar-close': !touchStatus && hasTouchButton && isModernDesign,
                        'aside-touch-bar-open': touchStatus && hasTouchButton && isModernDesign,
                    },
                )}>
                <div className='aside-head pt-3'>
                    <Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus}/>
                </div>
                <div className='aside-body'>
                    <NavigationLine/>
                    <Navigation menu={inicio} id='inicio'/>
                    <NavigationLine/>
                    {/*<Navigation menu={planteles} id='planteles'/>*/}
                    {/*<NavigationLine/>*/}
                    <Navigation menu={usuarios} id='usuarios'/>
                    {/*<NavigationLine/>*/}
                    <Navigation menu={niveles} id='niveles'/>
                    {/*<NavigationLine/>*/}
                    <Navigation menu={tarjetas} id='tarjetas'/>
                </div>
            </motion.aside>
            {asideStatus && hasTouchButton && isModernDesign && (
                <>
                    <motion.div className='aside-drag-area' ref={constraintsRef}/>
                    <Tooltips title='Toggle Aside' flip={['top', 'right']}>
                        <motion.div
                            className='aside-touch'
                            drag='x'
                            whileDrag={{scale: 1.2}}
                            whileHover={{scale: 1.1}}
                            dragConstraints={constraintsRef}
                            // onDrag={(event, info) => console.log(info.point.x, info.point.y)}
                            dragElastic={0.1}
                            style={{x, zIndex: 1039}}
                            onClick={() => x.set(x.get() === 0 ? asideWidthWithSpace : 0)}
                        />
                    </Tooltips>
                </>
            )}
        </>
    );
};

export default Aside;
