export const APP_ENV = process.env.REACT_APP_ENV

const environments = {
    local: {
        domain: 'http://localhost:8000',
        base_url: 'http://localhost:8000/api',
    },
    develop: {
        domain: '',
        base_url: '',
    },
    production: {
        domain: 'https://api.ideaflash.app',
        base_url: 'https://api.ideaflash.app/api',
    }
}

export const DOMAIN = environments[APP_ENV].domain;
export const BASE_URL = environments[APP_ENV].base_url;
