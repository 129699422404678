import axios from 'axios';
import {BASE_URL} from '../env';

const api = axios.create({
  baseURL: BASE_URL,
});

api.interceptors.request.use(
  function (config) {
    let user = localStorage.getItem('_user');
    user = JSON.parse(user);
    const accessToken = user?.access_token;
    config.headers['Authorization'] = `Bearer ${accessToken}`;

    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

// api.interceptors.response.use(
//   function (response) {
//     return response;
//   },
//   function (error) {
//     return Promise.reject(error);
//   },
// );

export default api;
