import React, {lazy} from 'react';
import {
  inicio,
  // planteles,
  usuarios,
  niveles,
  tarjetas,
} from './menu';
import {pages} from './pages';

import Login from '../pages/auth/login';

const INICIO = {
  INICIO: lazy(() => import('../pages/inicio/index')),
};

const PERFIL = {
  PERFIL: lazy(() => import('../pages/perfil/index')),
};

// const PLANTELES = {
//     PLANTELES: lazy(() => import('../pages/planteles/index')),
//     AGREGAR_PLANTEL: lazy(() => import('../pages/planteles/agregar-plantel/index')),
// }

const USUARIOS = {
  USUARIOS: lazy(() => import('../pages/usuarios/index')),
};

const NIVELES = {
  NIVELES: lazy(() => import('../pages/niveles/index')),
  // DETALLE_NIVEL: lazy(() => import('../pages/niveles/detalle-nivel/index')),
};

const TARJETAS = {
  TARJETAS: lazy(() => import('../pages/tarjetas/index')),
};

const publicPages = [
  /**
   * Login
   */
  {
    path: 'login',
    element: <Login />,
    exact: true,
  },
];

const authPages = [
  /**
   * Inicio
   */
  {
    path: inicio.inicio.path,
    element: <INICIO.INICIO />,
    exact: true,
  },

  /**
   * Perfil
   */
  {
    path: pages.perfil.path,
    element: <PERFIL.PERFIL />,
    exact: true,
  },

  /**
   * Plantels
   */
  // {
  //     path: planteles.planteles.path,
  //     element: <PLANTELES.PLANTELES/>,
  //     exact: true,
  // },
  // {
  //     path: pages.agregar_plantel.path,
  //     element: <PLANTELES.AGREGAR_PLANTEL/>,
  //     exact: true,
  // },

  /**
   * Plantels
   */
  {
    path: usuarios.usuarios.path,
    element: <USUARIOS.USUARIOS />,
    exact: true,
  },

  /**
   * Niveles
   */
  {
    path: niveles.niveles.path,
    element: <NIVELES.NIVELES />,
    exact: true,
  },
  // {
  //   path: pages.detalle_nivel.path,
  //   element: <NIVELES.DETALLE_NIVEL />,
  //   exact: true,
  // },

  /**
   * TARJETA
   */
  {
    path: tarjetas.tarjetas.path,
    element: <TARJETAS.TARJETAS />,
    exact: true,
  },

];

const contents = [...publicPages, ...authPages];

export default contents;
